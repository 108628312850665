import React from "react";

import {
  Card,
  CardMedia,
  CardContent,
  Typography,
  Grid,
} from "@material-ui/core";
import CustomButtonLink from "./customButtonLink";

const HomeCard = ({ src, alt, title, description, button, url }) => {
  return (
    <Card elevation={0} style={{ backgroundColor: "inherit" }}>
      <CardContent>
        <Typography variant="h4" align="center">
          {title}
        </Typography>
        <br />
        <CardMedia
          image={src}
          alt={alt}
          style={{
            height: 0, // Ensures the height is controlled by paddingTop
            paddingTop: "75%", // 16:9 aspect ratio
          }}
        />
        <br />
        <Typography align="center" style={{ height: "100px" }}>
          {description}
        </Typography>
      </CardContent>
      <Grid container justify="center">
        <CustomButtonLink border url={url}>
          {button}
        </CustomButtonLink>
      </Grid>
      <br />
    </Card>
  );
};

export default HomeCard;
