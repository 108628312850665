import React, { useState, useCallback, lazy, Suspense } from "react";

// Material-UI Core Components (Individually Imported)
import Container from "@material-ui/core/Container";
import {
  Stepper,
  Step,
  StepLabel,
  StepContent,
  StepIcon,
} from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import ButtonBase from "@material-ui/core/ButtonBase";
import HomeCard from "./homeCard";

// Material-UI Styles
import { makeStyles } from "@material-ui/core/styles";

// Material-UI Icons
import HourglassEmptyIcon from "@material-ui/icons/HourglassEmpty";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";

// External Components
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import CateringFAQ from "./cateringFAQ";

// Local Components
const Footer = lazy(() => import("./footer"));
const CateringInfo = lazy(() => import("./cateringInfo"));

const CateringThankYouPage = React.memo(({ phone }) => {
  const history = useHistory();

  const handleMenuLinkClick = useCallback(() => history.push("/menu"), []);
  return (
    <>
      <Card
        style={{
          backgroundColor: "rgb(77, 163, 250)",
          position: "relative",
        }}
        square
        elevation={0}
      >
        <Container>
          <Typography
            variant="h1"
            style={{
              marginRight: "auto",
              fontSize: phone ? null : "15rem",
              paddingRight: "20px",
            }}
          >
            Thank You
          </Typography>
          <br />
          <Grid style={{ maxWidth: "800px", marginLeft: "auto" }}>
            <Typography variant="h4">
              {" "}
              One of our team members will contact you soon.
            </Typography>
            <br />
            <Typography variant="h6">
              We’ll confirm the details, answer any questions, and discuss how
              we can make your event amazing.
            </Typography>
            <br />
            <Typography variant="h6">
              <strong>What to expect:</strong>
              <ul>
                <li>A quick chat to finalize your vision.</li>
                <li>Tailored recommendations to match your needs.</li>
                <li>
                  A price breakdown so you know exactly what you’re getting.
                </li>
              </ul>
            </Typography>
            <br />
            <Typography variant="h6">
              We aim to respond within 24 hours—typically by phone. If we're
              unable to reach you, we'll follow up with an email. In the
              meantime, feel free to explore our website and discover what makes
              CleanCrust stand out from the rest.
            </Typography>
          </Grid>

          <br />
          <br />
        </Container>
      </Card>
      <Container>
        <br />
        <Grid
          container
          direction="row"
          //   spacing={1}
          //   alignItems="center"
          justifyContent="center"
        >
          <Grid item sm={12} md={4} alignContent="center">
            <HomeCard
              src={"/assets/orderNowHomeCard.JPG"}
              title="Pop-ups"
              description={
                "Our local pizza pop-up runs locally throughout the week. Pre-Order now so you don't miss out on some delicious Neapolitan pizza."
              }
              url="/order"
              button={"Learn More"}
            />
          </Grid>
          <Grid item sm={12} md={4}>
            <HomeCard
              src={"/assets/thereIsNoPlanetB.JPG"}
              title="Sustainability"
              description="Learn about what we are doing to help stop climate change and how we prioritise animal wellfare with all our ingredient selection"
              url="/ethicalandsustainable"
              button="Learn More"
            />
          </Grid>

          <Grid item sm={12} md={4}>
            <HomeCard
              src={"/assets/uniDays.jpg"}
              title="Our Story"
              description="Discover the story of CleanCrust—how we turned a fun idea at the end of university into a thriving food truck business!"
              url={"/about"}
              button="Learn More"
            />
          </Grid>
        </Grid>
      </Container>
      <br />
      <Suspense fallback={<div>Loading...</div>}>
        <Footer />
      </Suspense>
    </>
  );
});

export default CateringThankYouPage;
