import React, { useState, useEffect } from "react";

import {
  Container,
  AppBar,
  Tabs,
  Tab,
  Typography,
  Grid,
} from "@material-ui/core";

const MenuNav = ({ alignCenter, deals, sides, mains, desserts, essential }) => {
  const [value, setValue] = useState("#deals");

  const handleTabClick = (e, value) => {
    const element = document.getElementById(value);

    if (!element) return;

    const height = element.offsetTop - 30;

    window.scrollTo({
      top: height,
      behavior: "smooth",
    });
  };
  const setValueFromHeight = () => {
    const dessertHeight = document.getElementById("#desserts")
      ? document.getElementById("#desserts").getBoundingClientRect().y - 40
      : -1;
    if (dessertHeight < 0) return setValue("#desserts");

    const mainHeight = document.getElementById("#mains")
      ? document.getElementById("#mains").getBoundingClientRect().y - 40
      : -1;
    if (mainHeight < 0) return setValue("#mains");

    const sideHeight = document.getElementById("#sides")
      ? document.getElementById("#sides").getBoundingClientRect().y - 40
      : -1;
    // console.log("side", sideHeight);
    if (sideHeight < 0) return setValue("#sides");

    const dealHeight = document.getElementById("#deals")
      ? document.getElementById("#deals").getBoundingClientRect().y - 40
      : -1;
    // console.log("deal", dealHeight);
    if (dealHeight < 0) return setValue("#deals");
    const caterMeHeight = document.getElementById("#essential")
      ? document.getElementById("#essential").getBoundingClientRect().y - 40
      : -1;
    // console.log("side", sideHeight);
    if (caterMeHeight < 0) return setValue("#sides");
  };

  useEffect(() => {
    window.addEventListener("scroll", setValueFromHeight);
    // Call handler right away so state gets updated with initial window size
    setValueFromHeight();
    // Remove event listener on cleanup
    return () => window.removeEventListener("scroll", setValueFromHeight);
  }, []);
  return (
    <AppBar
      position="sticky"
      style={{
        boxShadow: "rgb(0 0 0 / 20%) 0px 13px 10px -9px",
      }}
      elevation={0}
    >
      <Container>
        <Grid md={alignCenter ? 12 : 8}>
          <Tabs
            onChange={handleTabClick}
            value={value}
            // indicatorColor="primary"
            // textColor="primary"
            centered={alignCenter}
            // style={{ color: "white" }}
          >
            {essential ? (
              <Tab
                value={"#essential"}
                label={<Typography variant="h6">Essential</Typography>}
                style={{ textTransform: "none", padding: "0", margin: 0 }}
              />
            ) : null}
            {deals ? (
              <Tab
                value={"#deals"}
                label={<Typography variant="h6">Deals</Typography>}
                style={{ textTransform: "none", padding: "0", margin: 0 }}
              />
            ) : null}
            {sides ? (
              <Tab
                value={"#sides"}
                label={<Typography variant="h6">Sides</Typography>}
                style={{ textTransform: "none" }}
              />
            ) : null}
            {mains ? (
              <Tab
                value={"#mains"}
                label={<Typography variant="h6">Mains</Typography>}
                style={{ textTransform: "none" }}
              />
            ) : null}
            {desserts ? (
              <Tab
                value={"#desserts"}
                label={<Typography variant="h6">Desserts</Typography>}
                style={{ textTransform: "none" }}
              />
            ) : null}
          </Tabs>
        </Grid>
      </Container>
    </AppBar>
  );
};

export default MenuNav;
