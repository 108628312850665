import React from "react";
import {
  Container,
  Grid,
  Typography,
  Hidden,
  SvgIcon,
} from "@material-ui/core";
import CustomButtomLink from "./customButtonLink";
import { useLocation } from "react-router-dom";
import { ReactComponent as Logo } from "../logo.svg";

const Footer = (props) => {
  let location = useLocation();
  const generateBackgroundColor = () => {
    const path = location.pathname;
    if (path.indexOf("catering") !== -1) return "#4DA3FA";
    if (path.indexOf("thankyou") !== -1) return "#FAA34C";
    if (path.indexOf("workshops") !== -1) return "#A3E85F";
    if (path.indexOf("about") !== -1) return "#9883E5";
    if (path.indexOf("ethicalandsustainable") !== -1) return "rgb(255 185 94)";
    return "#4DA3FA";
  };
  return (
    <div style={{ backgroundColor: generateBackgroundColor() }}>
      <Container>
        <Grid container style={{ padding: "5% 0" }}>
          <Grid item md={3} sm={4} xs={12}>
            <Typography variant="h4">Explore</Typography>
            <div style={{ color: "white", padding: "5px 0" }}>
              <Typography variant="h6">
                <a
                  href="/"
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  home
                </a>
              </Typography>
              <Typography variant="h6">
                <a
                  href="/order"
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  order
                </a>
              </Typography>
              <Typography variant="h6">
                <a
                  href="/workshops"
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  workshops
                </a>
              </Typography>
              <Typography variant="h6">
                <a
                  href="/catering"
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  catering
                </a>
              </Typography>
              <Typography variant="h6">
                <a
                  href="/about"
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  about
                </a>
              </Typography>
              <Typography variant="h6">
                <a
                  href="/privacypolicy"
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  privacy policy
                </a>
              </Typography>
            </div>
          </Grid>
          <Grid item md={3} sm={4} xs={12}>
            <Typography variant="h4">Social</Typography>

            <div style={{ padding: "5px 0" }}>
              <Typography variant="h6" style={{ color: "white" }}>
                <a
                  href="https://www.instagram.com/cleancrustuk/"
                  target="_blank"
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  instagram
                </a>
              </Typography>
              <Typography variant="h6" style={{ color: "white" }}>
                <a
                  href="https://www.facebook.com/cleancrustuk/"
                  target="_blank"
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  facebook
                </a>
              </Typography>
            </div>
          </Grid>
          <Grid item md={3} sm={4} xs={12}>
            <Typography variant="h4">Contact</Typography>
            <div style={{ padding: "5px 0" }}>
              <Typography variant="h6">Email:</Typography>
              <Typography variant="h6" style={{ color: "white" }}>
                <a
                  href="mailto:hello@cleancrust.co.uk"
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  hello@cleancrust.co.uk
                </a>
              </Typography>

              <Typography variant="h6">Telephone:</Typography>
              <Typography variant="h6" style={{ color: "white" }}>
                <a
                  href="tel:+447753289571"
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  07753289571
                </a>
              </Typography>
            </div>
          </Grid>
          <Hidden smDown>
            <Grid item md={3} sm={12} xs={12} container justify="center">
              <SvgIcon
                component={Logo}
                viewBox="0 0 1000 1000"
                style={{ fontSize: "250px", width: "350px" }}
              />
            </Grid>
          </Hidden>
        </Grid>
        <br />
      </Container>
    </div>
  );
};

export default Footer;
