import React from "react";

import Card from "@material-ui/core/Card";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const CateringFAQ = ({}) => {
  const faqData = [
    {
      question: "What areas do you cover for catering?",
      answer:
        "Our travel range depends on the size and timing of the event. While we’re based in York, we’ve catered as far as Newcastle for larger events. If you're unsure whether we can travel to your location, feel free to reach out!",
    },
    {
      question: "How much does pizza catering cost?",
      answer:
        "Our pricing depends on the number of guests, package choice, and the event duration. Prices typically start from £12 per person or £500 minimum spend. Get in touch for a custom quote!",
    },
    {
      question: "What is included in your catering service?",
      answer:
        "We serve freshly made Neapolitan-style pizzas, typically by the slice, and provide everything needed for your guests, including plates, napkins, and condiments. Sides, salads, and drinks are also available upon request.",
    },
    {
      question: "How many pizzas can you make per hour?",
      answer:
        "We can cook up to 60 pizzas per hour, ensuring fast and fresh service for your guests. If you have specific time constraints, let us know, and a member of our team will be happy to advise on what’s possible.",
    },
    {
      question: "Can you cater for dietary requirements?",
      answer:
        "Yes! We offer vegetarian, vegan, gluten-free, and halal options. Please let us know about any allergies or special requests in advance.",
    },
    {
      question: "Do you need power and water at the venue?",
      answer:
        "Our pizza truck is fully self-sufficient. We do not require water access, but having it on-site is a bonus.",
    },
    {
      question: "How do I confirm a booking?",
      answer:
        "To secure your date, we require a deposit typically 50% of the total amount, with the remaining balance due a month before the event.",
    },
    {
      question: "Do you offer a refund if the event is cancelled?",
      answer:
        "Deposits are generally non-refundable, however, we understand things can happen and we will try our best to be find a solution",
    },
    {
      question: "Can you operate indoors or in bad weather?",
      answer:
        "Our pizza truck is designed for outdoor events, but we can operate under cover or in a well-ventilated indoor space with prior arrangements. If you’re concerned about the weather, we recommend having a backup plan, such as a marquee.",
    },
  ];
  return (
    <Grid container justifyContent="center">
      <Card style={{ backgroundColor: "inherit" }} elevation={0}>
        <Typography variant="h2" align="center">
          FAQ's
        </Typography>
        <br />
        <div>
          {" "}
          {faqData.map((faq, index) => (
            <Accordion
              key={index}
              square
              style={{ backgroundColor: "inherit" }}
            >
              <AccordionSummary
                // style={{ backgroundColor: "rgb(64, 138, 213)" }}
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`panel${index + 1}-content`}
                id={`panel${index + 1}-header`}
              >
                <Typography variant="h5">{faq.question}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography variant="h6" style={{ color: "white" }}>
                  <i>{faq.answer}</i>
                </Typography>
              </AccordionDetails>
            </Accordion>
          ))}
        </div>
      </Card>
    </Grid>
  );
};

export default CateringFAQ;
