import React, { useState } from "react";
import { GoogleMap, useJsApiLoader, Marker } from "@react-google-maps/api";
import { Card } from "@material-ui/core";
import LoadingPage from "../loadingPage";

const windmill = {
  lat: 54.06752313873679,
  lng: -1.2161779403686523,
};

const LocationCard = ({ session, width, height, elevation }) => {
  const [map, setMap] = useState(null);
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyD3xkh6wjiKvvjqfp-PKDvHxD2Gub5HJWA",
    libraries: ["places"],
  });
  const formatLocation = (location) => {
    if (!location) return windmill;
    return { lat: +location.lat, lng: +location.lng };
  };

  const onLoad = React.useCallback(function callback(map) {
    setMap(map);
  }, []);

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  if (session)
    return isLoaded ? (
      <Card
        style={{
          width: width || "350px",
          height: height || "430px",
          marginTop: "10px",
        }}
        elevation={elevation || 20}
      >
        <GoogleMap
          mapContainerStyle={{
            width: "100%",
            height: "430px",
          }}
          center={formatLocation(session.location)}
          zoom={15}
          onLoad={onLoad}
          onUnmount={onUnmount}
        >
          <Marker position={formatLocation(session.location)} />
          <></>
        </GoogleMap>
      </Card>
    ) : (
      <Card
        style={{ width: "350px", height: "430px", marginTop: "10px" }}
        elevation={20}
      >
        <LoadingPage />
      </Card>
    );
  return isLoaded ? (
    <Card
      style={{ width: "350px", height: "430px", marginTop: "10px" }}
      elevation={20}
    >
      <GoogleMap
        mapContainerStyle={{
          width: "100%",
          height: "430px",
        }}
        center={windmill}
        zoom={5}
        onLoad={onLoad}
        onUnmount={onUnmount}
      >
        <></>
      </GoogleMap>
    </Card>
  ) : (
    <Card
      style={{ width: "350px", height: "430px", marginTop: "10px" }}
      elevation={20}
    >
      <LoadingPage />
    </Card>
  );
};

export default LocationCard;
